import { api } from './api';
import { endpoints } from './endpoints';

const getCSEntries = async <TResponse>(
  entryCode: string,
  params?: Record<string, string | Record<string, string>>
) => {
  try {
    const response = await api.get<TResponse>(endpoints.getCSEntries(entryCode), {
      params,
    });
    return response;
  } catch (error) {
    console.error('Error fetching Contentstack entries:', error);
    return [] as TResponse;
  }
};

export { getCSEntries };
