import type { TProduct } from '@/common';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/atoms';
import { ProductCard } from '@/components/molecules/ProductCard';
import {} from '@/constants/brands';
import { cn } from '@/lib/utils';
import Image from 'next/image';

type THeroProductsCarouselProps = {
  products?: TProduct[];
  isCARegion: boolean;
};
export function HeroProductsCarousel({
  products,
  isCARegion,
}: Readonly<THeroProductsCarouselProps>) {
  const productsLength = products?.length ?? 0;
  const mockLength = productsLength > 4 ? 0 : 4 - productsLength;

  const mocksArray = Array.from({ length: mockLength }, (_, index) => ({
    id: `${Date.now()}-${index}`,
  }));

  function renderMocks() {
    return (
      <>
        {mocksArray.map((mockItem) => (
          <CarouselItem
            key={mockItem.id}
            className={cn('w-[139px] basis-auto px-0 pl-6 sm:w-auto sm:basis-1/4')}
          >
            <div className="rounded-sm">
              <Image
                src="/images/common/no-image.svg"
                alt="mock carousel product item"
                width={400}
                height={400}
              />
            </div>
          </CarouselItem>
        ))}
        <Image
          src="/images/common/mock-start.svg"
          alt="mock carousel product item start"
          width={400}
          height={400}
          className="absolute bottom-0 w-48"
          style={{ left: `calc(26% * ${productsLength})` }}
        />
      </>
    );
  }

  return (
    <Carousel className="flex-grow overflow-hidden" hasDots>
      <CarouselContent className="-ml-6 sm:-ml-6 relative">
        {products?.map((product) => (
          <CarouselItem
            key={product.internalProductId}
            className={cn('w-[139px] basis-auto px-0 pl-6 sm:w-auto sm:basis-1/4')}
          >
            <ProductCard product={product} hasBrand={false} isCARegion={isCARegion} centerContent />
          </CarouselItem>
        ))}
        {renderMocks()}
      </CarouselContent>
      <CarouselPrevious className="top-[27%] hidden lg:flex" size="lg" />
      <CarouselNext className="top-[27%] hidden lg:flex" size="lg" />
    </Carousel>
  );
}
