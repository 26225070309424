import type { TCSFeature } from '@/common';
import { Button } from '@/components/atoms';
import { NewFeaturesSegment } from '@/components/molecules/NewFeaturesSegment';
import { cn } from '@/lib/utils';
import { getCSEntries } from '@/services/contentstack/services';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';

type TNewFeaturesProps = {
  className?: string;
};
export async function NewFeatures({ className }: Readonly<TNewFeaturesProps>) {
  const t = useTranslations();

  const responseFeatures = await getCSEntries<{ entries: TCSFeature[] }>('b2b_feature', {
    asc: 'order',
  });

  return (
    <div className={cn('w-full', className)}>
      <div className="container py-10">
        <h2 className="h1 mb-12 text-center sm:mb-16">Explore Our New Features</h2>

        <div className="hidden grid-cols-2 gap-10 sm:grid">
          {responseFeatures.entries.map((feature) => (
            <div
              className="relative flex w-full flex-col overflow-hidden rounded-lg border border-border-default p-10"
              key={feature.title}
            >
              <Image
                className="absolute inset-0 h-full object-cover"
                src={feature.background.url}
                alt={feature.title}
                width={800}
                height={800}
                priority={false}
                sizes="100%"
              />

              <div className="absolute top-10 left-10 z-20 mb-4 w-fit rounded bg-bg-body shadow-md">
                <Image
                  src={feature.logo.url}
                  alt={feature.title}
                  width={200}
                  height={60}
                  className="w-auto"
                />
              </div>
              <Image
                className="relative z-10 mb-6 w-full"
                src={feature.image.url}
                alt={feature.title}
                width={200}
                height={200}
              />
              <div className="relative z-10">
                <p className="subtitle2-bold mb-2">{feature.title}</p>
                <p className="body1-regular mb-4 min-h-12 text-fg-weak">{feature.description}</p>
                <Button prefetch={false} shallow={true} as={Link} href={feature.url}>
                  {t('learnMore')}
                </Button>
              </div>
            </div>
          ))}
        </div>

        <NewFeaturesSegment features={responseFeatures.entries} />
      </div>
    </div>
  );
}
